import Icon from '@ant-design/icons';
import React from 'react';
import ExternalLinkIcon from '~/assets/svg/icon-arrow-external.svg?react';

import './ExternalLink.scss';

function ExternalLink({ href, title, type = 'primary', underline = true, bold = false }) {
  return (
    <a href={href} target='_blank' rel='noreferrer' className={`external-link ${type} ${underline ? 'underline' : ''} ${bold ? 'body2' : 'body1'}`}>
      {title}
      <Icon component={ExternalLinkIcon} />
    </a>
  );
}

export default ExternalLink;
