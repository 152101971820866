import { Tag } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const getNotificationColor = (conversation) => {
  const color = {
    light: true,
    color: '',
  };

  const responseNeededIn = conversation.response_needed_in;

  if (conversation.merchant_last_message_date) {
    color.color = 'default';
  } else if (responseNeededIn.late || !responseNeededIn.days) {
    color.color = 'error';
    if (responseNeededIn.late) {
      color.light = false;
    }
  } else if (responseNeededIn.hours <= 48) {
    color.color = 'warning';
  } else {
    color.color = 'warning';
    color.light = false;
  }
  return color;
};

const formatTimeNotification = (conversation, merchantLastMessageDate) => {
  const responseNeededIn = conversation.response_needed_in;
  if (conversation.merchant_last_message_date) {
    const now = dayjs();
    const daysAfterLastMessage = now.diff(merchantLastMessageDate, 'days');
    return `Il y a ${daysAfterLastMessage}j`;
  }
  // If any of the values is negative, display it as urgent
  if (responseNeededIn.late) {
    const overdue = responseNeededIn.days ? responseNeededIn.days : responseNeededIn.hours;
    const overdueUnit = responseNeededIn.days ? 'j' : 'h';
    return `Délai passé +${Math.abs(overdue)}${overdueUnit}`;
  }
  if (responseNeededIn.days > 1) {
    return `Échéance : ${responseNeededIn.days}j`;
  }
  if (responseNeededIn.days === 1) {
    const hours = responseNeededIn.hours + 24;
    return `Échéance : ${hours}h`;
  }
  return `Échéance : ${responseNeededIn.hours}h`;
};

function TimeNotification({ conversation }) {
  const merchantLastMessageDate = dayjs(conversation.merchant_last_message_date);
  // Only show the time notification if the conversation has a response_needed_in or if the last message from the merchant is older than 24 hours
  const showTimeNotification =
    conversation.response_needed_in || (conversation.merchant_last_message_date && merchantLastMessageDate.isBefore(dayjs().subtract(1, 'day')));
  return (
    <>
      {showTimeNotification && (
        <Tag
          className={`flex gap-xs items-center round caption1 small ${getNotificationColor(conversation).light && 'light'} ${!conversation.read_by_merchant && '!font-bold'}`}
          color={getNotificationColor(conversation).color}
        >
          {formatTimeNotification(conversation, merchantLastMessageDate)}
        </Tag>
      )}
    </>
  );
}

export default TimeNotification;
