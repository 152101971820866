import { CloseOutlined } from '@ant-design/icons';
import { Button, Divider, Flex, Input, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Dialogue from '~/components/shared/Dialogue/Dialogue';
import { useOrderDetailByIdNumber } from '~/hooks/useOrderDetails';

import ConversationOrderSummary from '../../ConversationsList/ConversationDetails/ConversationOrderSummary/ConversationOrderSummary';
import CreateConversationForm from './CreateConversationForm';

function CreateConversationModal({ activeMerchantId, setSelectedConversation, baseOrderIdNumber = '', customText = null }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmQuitModal, setShowConfirmQuitModal] = useState(false);
  const [orderNumber, setOrderNumber] = useState(baseOrderIdNumber);

  const { isLoading, isError, data: order } = useOrderDetailByIdNumber(orderNumber, activeMerchantId);

  const changeOrderNumber = (orderNumber) => {
    setOrderNumber(orderNumber);
  };

  const closeModal = () => {
    if (order) {
      setShowConfirmQuitModal(true);
    } else {
      confirmQuit();
    }
  };

  const confirmQuit = () => {
    setOrderNumber('');
    setShowConfirmQuitModal(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <Button onClick={() => setIsModalOpen(true)}>{customText || 'Nouveau message'}</Button>
      <Modal centered open={isModalOpen} onCancel={closeModal} footer={null} closeIcon={null} classNames={{ content: '!p-0' }} width={750}>
        <Flex vertical>
          <Flex className='py-s px-m' justify='space-between' align='center' gap={8}>
            <h2 className='h2'>Nouveau message</h2>
            <Button onClick={closeModal} type='link' className='btn-icon'>
              <CloseOutlined />
            </Button>
          </Flex>
          <Divider />
          <Flex className='p-m' vertical gap={16}>
            <Flex gap={16} align='center'>
              <Flex vertical gap={8}>
                <p className='caption2'>N° de commande</p>
                <Input
                  placeholder='241216E111873427'
                  defaultValue={baseOrderIdNumber}
                  value={orderNumber}
                  onChange={(e) => changeOrderNumber(e.target.value)}
                  onPressEnter={(e) => changeOrderNumber(e.target.value)}
                />
              </Flex>
              <ul className='list-disc body1 text-grey-medium ml-m'>
                <li>Collez le numéro de commande auquel associer la conversation.</li>
                <li>Vérifiez que c’est le bon destinataire.</li>
              </ul>
            </Flex>
            {orderNumber.length > 4 && orderNumber.length < 14 && (
              <Dialogue type='error' light message='Copiez puis collez le n° de commande ici pour éviter les erreurs.' />
            )}
            {isError && <Dialogue type='info' light message='Nous n’avons pas trouvé de commande correspondante.' />}
            {isLoading && (
              <Flex justify='center' align='center' className='grow py-m'>
                <Spin />
              </Flex>
            )}
            {order && (
              <div className='p-m rounded-sm bg-background'>
                <ConversationOrderSummary order={order} merchant={order.merchant} user={order.user} />
              </div>
            )}
            {order && <CreateConversationForm orderId={order.id} closeModal={confirmQuit} setSelectedConversation={setSelectedConversation} />}
          </Flex>
        </Flex>
      </Modal>
      <Modal open={showConfirmQuitModal} closeIcon={null} footer={null} maskClosable={false} centered>
        <Flex vertical gap={32}>
          <Flex vertical gap={16}>
            <p className='h2'>Êtes-vous sûr·e de vouloir quitter votre brouillon ?</p>
            <p className='body1'>Il sera supprimé après confirmation.</p>
          </Flex>
          <Flex gap={8} align='center'>
            <Button onClick={() => setShowConfirmQuitModal(false)} type='link' className='grow'>
              Annuler
            </Button>
            <Button type='primary' danger onClick={confirmQuit} className='grow'>
              Supprimer le brouillon
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}

export default connect(({ session }) => ({ activeMerchantId: session.active_merchant.id }))(CreateConversationModal);
