import { Flex } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import Attachment from './Attachment';

const messageTypeMapper = {
  customer: {
    position: 'self-start',
    backgroundColor: 'bg-dark-creme-background',
  },
  merchant: {
    position: 'self-end',
    backgroundColor: 'bg-lila-background',
  },
  default: {
    position: 'flex-start',
    backgroundColor: 'bg-grey-light',
  },
};

const getPositionOfMessage = (type) => {
  return messageTypeMapper[type]?.position || messageTypeMapper.default.position;
};

const getBackgroundColorOfMessage = (type) => {
  return messageTypeMapper[type]?.backgroundColor || messageTypeMapper.default.backgroundColor;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return dayjs(date).format('dddd D MMM HH:mm');
};

function Messages({ conversationId, messages }) {
  return (
    <div className='h-[50vh] overflow-auto w-full flex flex-col-reverse'>
      <Flex vertical gap={16}>
        {messages.map((message, index) => (
          <Flex key={index} vertical gap={8} className={`${getPositionOfMessage(message.type)} max-w-[60%]`}>
            {message.content && (
              <Flex className={`flex rounded-md p-m ${getBackgroundColorOfMessage(message.type)}`} vertical gap={8}>
                {/* transform every \n in <br /> */}
                <p className='body1 break-words' dangerouslySetInnerHTML={{ __html: message.content.replace(/\n/g, '<br />') }} />
              </Flex>
            )}
            {message.attachments.length > 0 && (
              <Flex gap={10} align='center'>
                {message.attachments.map((attachment) => (
                  <Attachment
                    key={attachment.id}
                    attachment={attachment}
                    conversationId={conversationId}
                    backgroundColor={getBackgroundColorOfMessage(message.type)}
                  />
                ))}
              </Flex>
            )}
            <p className={`body1 text-grey-medium ${getPositionOfMessage(message.type)}`}>{formatDate(message.created_on)}</p>
          </Flex>
        ))}
      </Flex>
    </div>
  );
}

export default Messages;
