import { App, Spin } from 'antd';
import React, { useState } from 'react';
import { useGetAttachmentUrl } from '~/hooks/useConversations';

import { getFileIconForAttachment } from '../SendMessage/SendMessageForm';

function Attachment({ conversationId, attachment, backgroundColor }) {
  const { notification } = App.useApp();
  const [isLoading, setIsLoading] = useState(false);
  const { refetch } = useGetAttachmentUrl(conversationId, attachment.id);

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data: url, error } = await refetch();
      if (error) {
        notification.open({
          className: 'notification-dialogue-error',
          message: "La pièce jointe n'existe plus",
        });
      } else if (url) {
        window.open(url, '_blank');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div onClick={handleClick} className='flex flex-col gap-xs w-[60px] cursor-pointer' title={attachment.name}>
      <div className={`rounded-sm h-[60px] flex justify-center items-center ${backgroundColor}`}>
        {isLoading ? <Spin size='small' /> : getFileIconForAttachment(attachment.type)}
      </div>
      <p className='caption1 text-grey-medium text-ellipsis whitespace-nowrap overflow-hidden'>{attachment.name}</p>
    </div>
  );
}

export default Attachment;
