import Icon from '@ant-design/icons';
import { App, Flex } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import CustomCheckmarkIcon from '~/assets/svg/icon-checkmark.svg?react';
import CustomCopyPaste from '~/assets/svg/icon-copy-paste.svg?react';
import CustomCreditCardIcon from '~/assets/svg/icon-credit-card.svg?react';
import CustomShippingTruckIcon from '~/assets/svg/icon-shipping-truck.svg?react';
import CustomUnhappyIcon from '~/assets/svg/icon-smiley-unhappy.svg?react';
import ExternalLink from '~/components/shared/ExternalLink/ExternalLink';
import { MerchantExternalRouting } from '~/helpers/general';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  // If date is not in the current year, display the year
  if (date.getFullYear() !== new Date().getFullYear()) {
    return dayjs(date).format('D MMM YYYY');
  }
  return dayjs(date).format('D MMM');
};

function ConversationOrderSummary({ user, order, merchant }) {
  const { message } = App.useApp();
  return (
    <Flex vertical gap={2} className='body1'>
      <p className='body2'>À propos de la commande :</p>
      {!user.deleted ? (
        <p>
          Prénom, Nom : {user.first_name} {user.last_name}
        </p>
      ) : (
        <p>Utilisateur supprimé</p>
      )}
      <Flex gap={8} align='center'>
        N° de commande : {order.id_number}
        <ExternalLink type='dark' href={MerchantExternalRouting.orderDetail(merchant.slug, order.id)} title='Détails de la commande' />
      </Flex>
      <p>Montant: {order.amount}€</p>
      <Flex gap={8} align='center'>
        <Flex gap={4} align='center' title={order.created_on}>
          <Icon component={CustomCreditCardIcon} />
          <p>Commandée : {formatDate(order.created_on)}</p>
        </Flex>
        <span className='text-grey'>/</span>
        {order.confirmed_on && (
          <>
            <Flex gap={4} align='center' title={order.confirmed_on}>
              <Icon component={CustomCheckmarkIcon} />
              <p>Confirmée : {formatDate(order.confirmed_on)}</p>
            </Flex>
            <span className='text-grey'>/</span>
          </>
        )}
        {order.sent_on && (
          <>
            <Flex gap={4} align='center' title={order.sent_on}>
              <Icon component={CustomShippingTruckIcon} />
              <p>Expédiée : {formatDate(order.sent_on)}</p>
            </Flex>
          </>
        )}
      </Flex>
      {(order.first_incident_on || order.tracking_url) && (
        <Flex gap={8} align='center'>
          {order.tracking_url && (
            <Flex gap={8} align='center'>
              <ExternalLink href={order.tracking_url} title={`${order.transporter_name} (${order.tracking_number})`} bold />
              <CustomCopyPaste
                width='24px'
                height='24px'
                className='text-primary cursor-pointer'
                title='Copier le lien du suivi de colis'
                onClick={() => navigator.clipboard.writeText(order.tracking_url) && message.info('Lien copié')}
              />
            </Flex>
          )}
          {order.tracking_url && order.first_incident_on && <span className='text-grey'>/</span>}
          {order.first_incident_on && (
            <Flex gap={4} align='center' title={order.first_incident_on}>
              <CustomUnhappyIcon width='1em' height='1em' />
              <p>Réclamation ouverte le : {formatDate(order.first_incident_on)}</p>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
}

export default ConversationOrderSummary;
