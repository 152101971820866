import Icon, { CloseCircleFilled, FileOutlined } from '@ant-design/icons';
import { App, Button, Flex, Form, Input } from 'antd';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import CustomAttachmentImageIcon from '~/assets/svg/icon-attachment-image.svg?react';
import CustomAttachmentPdfIcon from '~/assets/svg/icon-attachment-pdf.svg?react';
import CustomAttachmentIcon from '~/assets/svg/icon-attachment.svg?react';
import CustomSendIcon from '~/assets/svg/icon-send.svg?react';
import { compressImage } from '~/components/screens/AdDetails/CreationEditionForm/Fieldsets/PhotosGrid/PhotosGrid';

const ONEMO = 1048576;
const MAX_SIZE_BYTES = 10 * ONEMO;
const MAX_COMPRESSED_SIZE_BYTES = 3 * ONEMO;
const MAX_FILES = 5;

const isFileImage = (fileType) => fileType.match('image.*');
const isFilePdf = (fileType) => fileType === 'application/pdf';

export const getFileIconForAttachment = (fileType) => {
  if (isFileImage(fileType)) {
    return <Icon className='text-2xl' component={CustomAttachmentImageIcon} />;
  }
  if (isFilePdf(fileType)) {
    return <CustomAttachmentPdfIcon className='text-2xl' />;
  }
  return <FileOutlined className='text-2xl' />;
};

function SendMessageForm({ mutation, formName = 'sendMessage' }) {
  const { notification } = App.useApp();
  const [files, setFiles] = useState([]);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (!values.content && files.length === 0) {
      return;
    }
    if (files.length > MAX_FILES) {
      notification.open({
        className: 'notification-dialogue-error',
        message: `Vous ne pouvez pas envoyer plus de ${MAX_FILES} fichiers à la fois`,
      });
      return;
    }
    const formData = new FormData();
    if (values.content) {
      formData.append('message', values.content.replaceAll('\r\n', '\n'));
    }
    files.forEach((attachment) => {
      formData.append('attachments', attachment);
    });
    mutation.mutate(formData, {
      onSuccess: () => {
        setFiles([]);
        form.resetFields();
      },
      onError: (error) => {
        notification.open({
          className: 'notification-dialogue-error',
          message: error.message,
        });
      },
    });
  };

  const onDrop = (acceptedFiles) => {
    const newFiles = [];
    acceptedFiles.forEach((file) => {
      // If file name is of an image type, we compress it
      if (isFileImage(file.type)) {
        compressImage(file, MAX_COMPRESSED_SIZE_BYTES).then((compressedFile) => {
          setFiles((prevFiles) => [...prevFiles, compressedFile]);
        });
      } else {
        newFiles.push(file);
      }
    });
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  return (
    <Flex vertical gap={8} align='center' className='w-full'>
      <Form form={form} name={formName} onFinish={onFinish} className='w-full'>
        <Flex gap={8}>
          <Form.Item name='content' className='grow'>
            <Input.TextArea placeholder='Saisissez votre réponse' />
          </Form.Item>
          <Dropzone onDrop={onDrop} disabledStyle={{}} maxSize={MAX_SIZE_BYTES}>
            <Button className='btn-icon' type='text' size='large'>
              <Icon component={CustomAttachmentIcon} />
            </Button>
          </Dropzone>
          <Form.Item noStyle>
            <Button type='primary' className='btn-icon' size='large' htmlType='submit' loading={mutation.isPending} disabled={mutation.isPending}>
              {!mutation.isPending && <Icon component={CustomSendIcon} />}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
      {files.length > 0 && (
        <Flex gap={10} align='center' className='self-start overflow-auto max-w-[70vw]'>
          {files.map((file) => (
            <Flex key={file.name} title={file.name} gap={4} vertical className='w-[60px]'>
              <div className='rounded-sm bg-lila-background h-[60px] relative flex justify-center items-center'>
                {getFileIconForAttachment(file.type)}
                <span
                  className='absolute top-0 right-0.5 cursor-pointer'
                  onClick={() => setFiles((prevFiles) => prevFiles.filter((f) => f.name !== file.name))}
                >
                  <CloseCircleFilled />
                </span>
              </div>
              <p className='caption1 text-grey-medium text-ellipsis whitespace-nowrap overflow-hidden'>{file.name}</p>
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  );
}

export default SendMessageForm;
