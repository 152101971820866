import Icon from '@ant-design/icons';
import { Flex, Tooltip } from 'antd';
import _ from 'lodash';
import React from 'react';
import QuestionMarkIcon from '~/assets/svg/icon-question-mark.svg?react';
import SearchInput from '~/components/shared/SearchInput/SearchInput';
import { SEARCH_PARAMETERS_API_IDS, STATUS_API_IDS } from '~/helpers/conversation';
import { HELP_MESSAGERIE_LINK } from '~/helpers/general';

import CreateConversationModal from './CreateConversationModal/CreateConversationModal';

function ConversationSearch({ params, changeParams, setSelectedConversation }) {
  const onGoingStatus = STATUS_API_IDS.OPEN;
  const closedStatus = [STATUS_API_IDS.CLOSED_BY_MERCHANT, STATUS_API_IDS.CLOSED_AUTO];

  const isActive = (status) => {
    return _.isEqual(params[SEARCH_PARAMETERS_API_IDS.STATUS], status) ? ' text-primary border-b-2 border-x-0 border-t-0 border-solid border-primary' : '';
  };

  const changeCurrentTab = (status) => () => {
    if (params[SEARCH_PARAMETERS_API_IDS.STATUS] === status) return;
    changeParams({
      ...params,
      [SEARCH_PARAMETERS_API_IDS.STATUS]: status,
    });
  };

  const searchByOrderNumber = (value) => {
    changeParams({
      [SEARCH_PARAMETERS_API_IDS.ORDER_ID]: value,
    });
  };

  return (
    <Flex vertical gap={8}>
      <Flex gap={8} align='center'>
        <h4 className='h4'>Messagerie</h4>
        <Tooltip title='Lien vers la formation' placement='bottom'>
          <a target='_blank' rel='noopener noreferrer' href={HELP_MESSAGERIE_LINK}>
            <Icon className='text-[30px]' component={QuestionMarkIcon} />
          </a>
        </Tooltip>
      </Flex>
      <Flex gap={24} align='center'>
        <Flex gap={40} align='center' className='w-1/2'>
          <Flex
            gap={12}
            align='center'
            className={`cursor-pointer py-m hover:text-primary${isActive(onGoingStatus)}`}
            onClick={changeCurrentTab(onGoingStatus)}
          >
            <p className='h4'>En cours</p>
          </Flex>
          <Flex gap={12} align='center' className={`cursor-pointer py-m hover:text-primary${isActive(closedStatus)}`} onClick={changeCurrentTab(closedStatus)}>
            <p className='h4'>Clôturées</p>
          </Flex>
        </Flex>
        <CreateConversationModal setSelectedConversation={setSelectedConversation} />
        <SearchInput placeholder='N° de commande' defaultValue={params[SEARCH_PARAMETERS_API_IDS.ORDER_ID]} search={searchByOrderNumber} />
      </Flex>
    </Flex>
  );
}
export default ConversationSearch;
