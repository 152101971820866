import Icon from '@ant-design/icons';
import { App, Flex } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import LightIcon from '~/assets/svg/icon-light.svg?react';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import Dialogue from '~/components/shared/Dialogue/Dialogue';
import { AD_DETAILS_API_IDS, AD_STATUS, canProductBeUpdated, validateFields } from '~/helpers/adDetails';
import { RoutingUrl } from '~/helpers/general';
import { CLEAN_FORM_STATE, DUPLICATE_PRODUCT } from '~/store/reducers/products';
import { createAd, loadFormSchemaThunk, updateAd } from '~/store/reducers/products/actions';

import AnchorNavigation from './AnchorNavigation/AnchorNavigation';
import ButtonBar from './ButtonBar/ButtonBar';
import './CreationEditionForm.scss';
import {
  Attributes,
  Category,
  Comment,
  Description,
  FormItemPanel,
  Inspirations,
  PhotosGrid,
  Price,
  ShippingOptions,
  State,
  Storage,
  Title,
} from './Fieldsets';
import FocusBar from './FocusBar/FocusBar';
import LeavingConfirmationModal from './Modals/LeavingConfirmationModal';
import PhotoroomNotification from './Modals/PhotoroomNotification';
import PublishedModal from './Modals/PublishedModal';

const STATE_KEY = 'state';
const SHIPPING_OPTIONS_KEY = 'shipping_options';
const COMMENT_KEY = 'comment';
const STORAGE_KEY = 'storage';

const labelMap = {
  [AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.TITLE]: 'Titre',
  [AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE]: 'Catégorie',
  [AD_DETAILS_API_IDS.IMAGES.UPLOAD]: 'Photos',
  [STATE_KEY]: 'État',
  [AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY]: 'Caractéristiques',
  [AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.DESCRIPTION]: 'Description',
  [AD_DETAILS_API_IDS.PROMOTION.INSPIRATIONS]: 'Sélections',
  [AD_DETAILS_API_IDS.TECHNICAL_INFO.PRICE]: 'Prix',
  [STORAGE_KEY]: 'Stockage',
  [SHIPPING_OPTIONS_KEY]: 'Options de livraison',
  [COMMENT_KEY]: 'Commentaire',
};

const errorMap = {
  required: (plural) => `Champ${plural ? 's' : ''} requis`,
  max_length: (plural) => `Champ${plural ? 's' : ''} trop long`,
  min_length: (plural) => `Champ${plural ? 's' : ''} trop court`,
};

const errorSuffixMap = {
  max_length: (limit) => ` (limite : ${limit})`,
  min_length: (limit) => ` (minimum : ${limit})`,
};

function areFieldsEquals(modifiedFields, initial_form_fields, destroy = false) {
  let isEqual = true;

  Object.keys(modifiedFields).forEach((key) => {
    if (modifiedFields[key] && Array.isArray(modifiedFields[key])) {
      let isArrayEqual = modifiedFields[key].length === initial_form_fields[key].length;
      modifiedFields[key].forEach((arrayValue, index) => {
        if (!_.isEqual(initial_form_fields[key][index], arrayValue)) {
          isEqual = false;
          isArrayEqual = false;
        }
      });
      if (!isArrayEqual) {
        isEqual = false;
      } else if (destroy) {
        delete modifiedFields[key];
      }
    } else if (modifiedFields[key] && typeof modifiedFields[key] === 'object') {
      Object.keys(modifiedFields[key]).forEach((sub_key) => {
        if (_.isEqual(initial_form_fields[key][sub_key], modifiedFields[key][sub_key])) {
          if (destroy) {
            delete modifiedFields[key][sub_key];
          }
        } else {
          isEqual = false;
        }
      });
      if (Object.keys(modifiedFields[key]).length === 0 && destroy) {
        delete modifiedFields[key];
      }
    } else if (_.isEqual(initial_form_fields[key], modifiedFields[key])) {
      if (destroy) {
        delete modifiedFields[key];
      }
    } else {
      isEqual = false;
    }
  });
  return isEqual;
}

function createFieldSetsArray(components, showFullForm, isCommentFirst) {
  const title = components[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.TITLE];
  const categories = components[AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE];
  const images = components[AD_DETAILS_API_IDS.IMAGES.UPLOAD];
  const attributes = {
    key: AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY,
    additional_components: [components[AD_DETAILS_API_IDS.TECHNICAL_INFO.WEIGHT], components[AD_DETAILS_API_IDS.TECHNICAL_INFO.STOCK]],
  };
  const description = components[AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.DESCRIPTION];
  const inspirations = components[AD_DETAILS_API_IDS.PROMOTION.INSPIRATIONS];
  const price = components[AD_DETAILS_API_IDS.TECHNICAL_INFO.PRICE];

  const storage = {
    key: STORAGE_KEY,
    sku: components[AD_DETAILS_API_IDS.TECHNICAL_INFO.SKU],
    location: components[AD_DETAILS_API_IDS.TECHNICAL_INFO.LOCATION],
  };

  const fieldsets = [title, categories, images];

  if (showFullForm) {
    fieldsets.push(STATE_KEY, attributes, description, inspirations, price, storage, SHIPPING_OPTIONS_KEY);

    if (isCommentFirst) {
      fieldsets.unshift(COMMENT_KEY);
    } else {
      fieldsets.push(COMMENT_KEY);
    }
  }

  return fieldsets;
}

function FieldSet({ fieldset, editionMode, productId, initialOpenAICount, restrictStockChange }) {
  const key = fieldset.key ? fieldset.key : fieldset;

  switch (key) {
    case AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.TITLE:
      return <Title component={fieldset} />;

    case AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE:
      return <Category component={fieldset} editionMode={editionMode} />;

    case AD_DETAILS_API_IDS.IMAGES.UPLOAD:
      return <PhotosGrid component={fieldset} />;

    case STATE_KEY:
      return <State />;

    case AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY:
      return <Attributes component={fieldset} restrictStockChange={restrictStockChange} />;

    case AD_DETAILS_API_IDS.TITLE_AND_DESCRIPTION.DESCRIPTION:
      return <Description component={fieldset} initialOpenAICount={initialOpenAICount} productId={productId} />;

    case AD_DETAILS_API_IDS.PROMOTION.INSPIRATIONS:
      return <Inspirations component={fieldset} />;

    case AD_DETAILS_API_IDS.TECHNICAL_INFO.PRICE:
      return <Price component={fieldset} editionMode={editionMode} />;

    case STORAGE_KEY:
      return <Storage component={fieldset} editionMode={editionMode} />;

    case SHIPPING_OPTIONS_KEY:
      return <ShippingOptions />;

    case COMMENT_KEY:
      return <Comment editionMode={editionMode} />;

    default:
      return null;
  }
}

function CreationEditionForm(props) {
  const [saving, setSaving] = useState({
    is: false,
    publish: false,
  });
  const [showPublishModal, setShowPublishModal] = useState(false);
  const { notification } = App.useApp();

  // Redux props
  const { dispatch, all_form_components, form_fields, validation_rules, product_status, initial_form_fields } = props;

  // AdDetails props
  const { schema, productId, history, navigation, isFocus, panelRef } = props;

  // Stop saving quick function
  const stopSaving = () => {
    setSaving({
      is: false,
      publish: false,
    });
  };

  if ((schema && Object.keys(schema).length === 0) || (form_fields && Object.keys(form_fields).length === 0)) {
    return null;
  }

  // History functions
  const goBack = () => {
    history.push(RoutingUrl.catalogue());
  };

  const goToCreation = () => {
    dispatch({ type: CLEAN_FORM_STATE });
    dispatch(loadFormSchemaThunk(null));
  };

  const reloadEdition = () => {
    dispatch({ type: CLEAN_FORM_STATE });
    dispatch(loadFormSchemaThunk(productId));
  };

  const goToEdition = (productId) => {
    history.push(RoutingUrl.adEdition([productId]));
  };

  // Success notification function
  const createPublishNotification = () => {
    const subtext = productId
      ? "Les modifications apparaîtront d'ici quelques minutes."
      : "Elle apparaîtra d'ici quelques minutes dans vos annonces et sur le site Label Emmaüs.";
    notification.open({
      className: 'custom-modal-feedback success',
      message: (
        <p className='body1'>
          Votre annonce a bien été publiée.
          <br />
          {subtext}
        </p>
      ),
      placement: 'bottom',
    });
  };

  const createDraftNotification = () => {
    notification.open({
      className: 'custom-modal-feedback success',
      message: "Votre annonce a bien été enregistrée en brouillon. Elle apparaîtra d'ici quelques minutes.",
      placement: 'bottom',
    });
  };

  // Error display for fails in Saving
  const displayError = (errorMessage) => {
    let error = errorMessage.toString();
    if (Array.isArray(errorMessage)) {
      error = errorMessage
        .map((errorItem) => {
          if (typeof errorItem === 'object') {
            return errorItem?.field + ':' + (errorItem?.msg || []).join(', ');
          }
          return errorItem.toString();
        })
        .join(',');
    }
    notification.open({
      className: 'notification-dialogue-error',
      message: 'Une erreur est survenue lors de la sauvegarde de votre annonce',
      description: error,
    });
  };

  const saveProduct = (publish = false) => {
    if (canProductBeUpdated(product_status)) {
      const { isValid, errors } = validateFields(publish, form_fields, validation_rules, all_form_components);
      if (isValid) {
        setSaving({
          is: true,
          publish: publish,
        });
        if (productId) {
          // Update
          const modifiedFields = _.cloneDeep(form_fields);
          if (!areFieldsEquals(modifiedFields, initial_form_fields, true) || (publish && product_status !== AD_STATUS.ACTIVE.API_ID)) {
            dispatch(
              updateAd(
                {
                  productId: productId,
                  modifiedFields: modifiedFields,
                  publish: publish && product_status !== AD_STATUS.ACTIVE.API_ID,
                },
                () => {
                  stopSaving();
                  if (!publish) {
                    createDraftNotification();
                  } else {
                    createPublishNotification();
                    if (product_status === AD_STATUS.DRAFT.API_ID) {
                      reloadEdition();
                    }
                  }
                },
                (error) => {
                  stopSaving();
                  displayError(error);
                }
              )
            );
          } else {
            stopSaving();
          }
        } else {
          // Create
          dispatch(
            createAd(
              publish,
              (productId) => {
                stopSaving();
                if (!publish) {
                  createDraftNotification();
                  goToEdition(productId);
                } else {
                  // Show publish modal
                  setShowPublishModal(true);
                }
              },
              (error) => {
                stopSaving();
                displayError(error);
              }
            )
          );
        }
      } else {
        const errorItems = Object.keys(errors).map((key) => {
          if (errors[key].length === 0) {
            return null;
          }

          return (
            <Flex vertical gap={4} key={key}>
              <p className='body1'>{errorMap[key](errors[key].length > 1)} :</p>
              <ul className='error-list'>
                {errors[key].map((error, index) => (
                  <li key={`${key}-${index}`} className='caption1'>
                    {error.label}
                    {error.limit !== true && error.limit && errorSuffixMap[key](error.limit)}
                  </li>
                ))}
              </ul>
            </Flex>
          );
        });
        notification.open({
          className: 'notification-dialogue-error',
          message: `Remplissez les informations manquantes concernant votre article pour ${publish ? 'le publier' : "l'enregister"}.`,
          description: (
            <Flex vertical gap={16}>
              {errorItems}
            </Flex>
          ),
        });
      }
    }
  };

  const duplicate = (save = false) => {
    if (save) {
      saveProduct();
    }
    dispatch({
      type: DUPLICATE_PRODUCT,
    });
  };

  // Conditions for the form based on the form status
  const editionMode = !!productId;
  const showFullForm = (form_fields[AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE] || []).length > 0;
  const isCommentFirst = editionMode && initial_form_fields[AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY]?.[AD_DETAILS_API_IDS.ATTRIBUTES.COMMENT]?.length > 0;
  const restrictStockChange = editionMode && product_status === AD_STATUS.ACTIVE.API_ID;

  const blockingHistory = !areFieldsEquals(form_fields, initial_form_fields);

  // Form Components
  const formComponents = all_form_components;
  const fieldsets = createFieldSetsArray(formComponents, showFullForm, isCommentFirst);

  const initialOpenAICount = schema['components'][0][AD_DETAILS_API_IDS.OPENAI_CALLS_COUNT];
  const productUrl = schema['components'][0][AD_DETAILS_API_IDS.PRODUCT_URL];

  const anchorItems = fieldsets.map((fieldset) => {
    const key = fieldset.key ? fieldset.key : fieldset;
    return {
      key: key,
      href: `#${key}`,
      title: <p className='body1 text-grey-medium'>{labelMap[key]}</p>,
    };
  });

  const pageTitleText = editionMode ? 'Éditer' : 'Créer une annonce';

  return (
    <div className='ad-details-wrapper'>
      <div>
        {!isFocus ? (
          <h4 className='h4 ad-details-title'>{pageTitleText}</h4>
        ) : (
          <FocusBar pageTitleText={pageTitleText} goBack={goBack} productUrl={productUrl} />
        )}{' '}
      </div>
      <AnchorNavigation anchorItems={anchorItems} panelRef={panelRef} />

      <div className='ad-detail-form-wrapper'>
        <Flex justify='space-between' align='center' className='ad-details-form-header'>
          <p className='body1'>
            <Asterisk /> Champs obligatoires
          </p>
        </Flex>
        {!editionMode && (
          <Dialogue type='info' light>
            <Icon className='icon' component={LightIcon} />
            <div>
              <p className='body2'>Nos recommandations</p>
              <p className='body1'>Un titre soigné est essentiel pour le référencement de l’annonce.</p>
              <br />
              <ol className='body1'>
                <li>Saisissez votre titre</li>
                <li>Sélectionnez une catégorie suggérée</li>
                ou
                <li>Recherchez ou sélectionnez une catégorie</li>
              </ol>
              <br />
              <p className='body1'>Seule la catégorie est pré-requise pour créer une annonce.</p>
            </div>
          </Dialogue>
        )}
        {fieldsets.map((fieldset, index) => (
          <FormItemPanel
            id={fieldset.key ? fieldset.key : fieldset}
            key={`fieldset-${index}`}
            classNameWrapper={fieldset === COMMENT_KEY ? 'comment-form-item-panel' : null}
          >
            <FieldSet
              fieldset={fieldset}
              editionMode={editionMode}
              productId={productId}
              initialOpenAICount={initialOpenAICount}
              restrictStockChange={restrictStockChange}
            />
          </FormItemPanel>
        ))}
      </div>
      <ButtonBar
        panelRef={panelRef}
        editionMode={editionMode}
        productStatus={product_status}
        saving={saving}
        navigation={navigation}
        saveProduct={saveProduct}
        duplicate={{
          link: RoutingUrl.adCreation(),
          event: () => duplicate(true),
        }}
        goBack={goBack}
        productUrl={productUrl}
      />

      <PublishedModal
        show={showPublishModal}
        setShow={setShowPublishModal}
        goBack={goBack}
        goToCreation={goToCreation}
        duplicate={() => {
          duplicate(false);
          goToCreation();
        }}
        createPublishNotification={createPublishNotification}
      />

      <LeavingConfirmationModal isListening={blockingHistory} />

      {saving.is && saving.publish && (!productId || product_status === AD_STATUS.DRAFT.API_ID) && <PhotoroomNotification />}
    </div>
  );
}

function mapStateToProps(state) {
  let {
    products: { form_fields, initial_form_fields, form_schema, validation_rules, all_form_components, current_form_status },
  } = state;
  return {
    all_form_components,
    initial_form_fields,
    form_fields,
    form_schema,
    validation_rules,
    product_status: current_form_status,
  };
}

export default connect(mapStateToProps)(CreationEditionForm);
