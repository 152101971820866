import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useCreateConversation } from '~/hooks/useConversations';

import SendMessageForm from '../../ConversationsList/ConversationDetails/SendMessage/SendMessageForm';

function CreateConversationForm({ orderId, closeModal, setSelectedConversation }) {
  const queryClient = useQueryClient();

  const onSuccessOfMutation = (data) => {
    setSelectedConversation(data.id);
    closeModal();
  };
  const createConversationMutation = useCreateConversation(orderId, queryClient, onSuccessOfMutation);

  return <SendMessageForm mutation={createConversationMutation} formName={`create-conversation-${orderId}`} />;
}

export default CreateConversationForm;
