import Icon from '@ant-design/icons';
import { Flex } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomMessageIcon from '~/assets/svg/icon-message.svg?react';
import { defaultParams as conversationDefaultParams } from '~/helpers/conversation';
import { RoutingUrl } from '~/helpers/general';
import { useMessagerieSummary } from '~/hooks/useConversations';

function MessagerieSummary({ activeMerchantId }) {
  const { isLoading, data: summary } = useMessagerieSummary(activeMerchantId);
  if (isLoading || (!summary.overdue && !summary.to_respond_within_24h && !summary.to_respond_within_48h)) {
    return <div className='mt-[-24px] no-summary' />;
  }
  return (
    <Link to={RoutingUrl.conversations([], conversationDefaultParams)}>
      <Flex vertical gap={4} className='px-l py-m bg-[#EDE5DE] rounded-md text-grey-dark'>
        <p className='body2'>Vous avez des nouveaux messages</p>
        <Flex gap={4} align='center'>
          <div className='relative'>
            <Icon component={CustomMessageIcon} className='text-[30px]' />
            <span className='w-[12px] h-[12px] rounded-full bg-error absolute right-0 top-[1px] !opacity-100' />
          </div>
          <ul className='list-disc body1 ml-l'>
            {!!summary.overdue && <li>{summary.overdue} en délai dépassé</li>}
            {!!summary.to_respond_within_24h && <li>{summary.to_respond_within_24h} réponses requises sous 24h</li>}
            {!!summary.to_respond_within_48h && <li>{summary.to_respond_within_48h} réponses requises sous 48h</li>}
          </ul>
        </Flex>
      </Flex>
    </Link>
  );
}

export default connect((state) => ({ activeMerchantId: state.session.active_merchant.id }))(MessagerieSummary);
