import { Flex } from 'antd';
import React, { useState } from 'react';
import { SEARCH_PARAMETERS_API_IDS } from '~/helpers/conversation';

import ConversationSearch from './ConversationSearch/ConversationSearch';
import ConversationsList from './ConversationsList/ConversationsList';
import MessagerieSatisfaction from './MessagerieSatisfaction';

function retrieveParamsFromURL(params) {
  if (params === '') return {};

  const paramsObject = {};
  const URLGetParams = new URLSearchParams(params);
  Object.keys(SEARCH_PARAMETERS_API_IDS).forEach((key) => {
    const paramKey = SEARCH_PARAMETERS_API_IDS[key];
    const value = URLGetParams.get(paramKey);
    if (value) {
      if (value.includes(',')) {
        paramsObject[paramKey] = value.split(',');
      } else {
        paramsObject[paramKey] = value;
      }
    }
  });
  return paramsObject;
}

export function paramsToURL(params, exclude = []) {
  const URLGetParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    if (params[key] === '' || key in exclude) return;
    URLGetParams.append(key, params[key]);
  });
  return '?' + URLGetParams.toString();
}

function Conversations({ history }) {
  const params = retrieveParamsFromURL(location.search);
  const [selectedConversation, setSelectedConversation] = useState(null);

  const changeParams = (newParams) => {
    setSelectedConversation(null);
    history.push({
      pathname: location.pathname,
      search: paramsToURL(newParams),
    });
  };

  return (
    <>
      <Flex className='mt-xl px-l pb-2xl h-[96.5%] relative' vertical gap={32}>
        <ConversationSearch params={params} changeParams={changeParams} setSelectedConversation={setSelectedConversation} />
        <ConversationsList params={params} selectedConversation={selectedConversation} setSelectedConversation={setSelectedConversation} />
        <MessagerieSatisfaction />
      </Flex>
    </>
  );
}

export default Conversations;
