import { RightOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Checkbox, Drawer, Flex, Input, InputNumber, Modal, Radio, Switch } from 'antd';
import React, { useRef, useState } from 'react';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import CustomSelect from '~/components/shared/CustomSelect/CustomSelect';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { api } from '~/services/api';
import { HANDLE_CHANGE } from '~/store/reducers/products';
import breakpoints from '~/styles/breakpoints.module.scss';

import './Attribute.scss';

const breakpointMobile = parseInt(breakpoints.xsMax.slice(0, -2));

function InputOnlyNumber({ propagateChange, attribute, defaultValue, askConfirmation }) {
  const [value, setValue] = useState(defaultValue);
  const [showModal, setShowModal] = useState(false);
  const valueTemp = useRef(null);

  const { key, label, type, unit, cleaner, confirmationTexts } = attribute;

  const step = type === AD_DETAILS_API_IDS.TYPES.DECIMAL ? 0.1 : 1;

  const cleanNumberValue = (val) => {
    if (val === '' || val < 0) {
      return '';
    }
    const cleanedVal = val.toString().replace(',', '.');
    let parsedValue = type === AD_DETAILS_API_IDS.TYPES.DECIMAL ? parseFloat(cleanedVal) : parseInt(cleanedVal);
    parsedValue = isNaN(parsedValue) ? '' : parsedValue;
    return cleaner ? cleaner(parsedValue) : parsedValue;
  };

  const handleModal = (confirm = false) => {
    if (confirm) {
      setValue(valueTemp.current);
      propagateChange(key, valueTemp.current);
    }
    setShowModal(false);
  };

  const changeValue = (val) => {
    if (askConfirmation && val !== value) {
      valueTemp.current = val;
      setShowModal(true);
    } else {
      setValue(val);
      propagateChange(key, val);
    }
  };

  return (
    <>
      <InputNumber
        id={key}
        value={value}
        decimalSeparator=','
        onStep={(value) => changeValue(cleanNumberValue(value))}
        onBlur={(elem) => changeValue(cleanNumberValue(elem.target.value))}
        addonAfter={unit}
        step={step}
        controls
      />
      {askConfirmation && (
        <Modal open={showModal} closeIcon={null} footer={null} maskClosable={false}>
          <Flex vertical gap={32}>
            <Flex vertical gap={16}>
              <p className='h2'>Êtes-vous sûr·e de vouloir changer votre {label} !</p>
              <p className='body1'>
                Actuel : {value}
                <br />
                Valeur voulu: {valueTemp.current}
              </p>
              {confirmationTexts &&
                confirmationTexts.map((text, index) => (
                  <p key={`confirmation-text-${index}`} className='body1'>
                    {text}
                  </p>
                ))}
            </Flex>
            <Flex vertical gap={16}>
              <Button type='primary' onClick={() => handleModal(true)}>
                Oui, je suis sûr·e
              </Button>
              <Button onClick={() => handleModal()}>Annuler</Button>
            </Flex>
          </Flex>
        </Modal>
      )}
    </>
  );
}

function AutocompleteInput({ propagateChange, attribute, defaultValue }) {
  const { key } = attribute;
  const [autocompleteList, setAutocompleteList] = useState([]);

  const onSearch = (value) => {
    if (value !== '' && value.length >= 3) {
      api.get(`/attributes/autocomplete/?text=${encodeURIComponent(value)}&attribute_key=${key}`).then((response) => {
        if (response.success) {
          setAutocompleteList(response.data.slice(0, 20).map((val) => ({ label: val, value: val })));
        }
      });
    }
    propagateChange(key, value);
  };
  return <AutoComplete id={key} defaultValue={defaultValue} options={autocompleteList} onSearch={onSearch} onSelect={(val) => propagateChange(key, val)} />;
}

function SwitchInput({ propagateChange, attribute, defaultValue }) {
  const { key } = attribute;
  return <Switch id={key} defaultChecked={defaultValue} onChange={(val) => propagateChange(key, val)} />;
}

const CHOICES_LIMIT_LIST = 7;

function RadioInput({ propagateChange, attribute, defaultValue }) {
  const { key, groupedChoices, choices } = attribute;
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const changeValue = (val, onChangeAdditional = () => {}) => {
    onChangeAdditional();
    propagateChange(key, val);
    setCurrentValue(val);
  };

  const radioGroup = (onChangeAdditional = () => {}) => (
    <Radio.Group
      className='custom-check-group'
      id={key}
      defaultValue={defaultValue}
      value={currentValue}
      onChange={(e) => changeValue(e.target.value, onChangeAdditional)}
    >
      {groupedChoices.map((choices, index) => (
        <Flex key={`group-${index}`} className='grouped-choices-wrapper' wrap='wrap' gap={16}>
          {choices.map(({ id, value, additionalContent }) => (
            <Radio
              key={id}
              value={value}
              onClick={(e) => {
                if (e.target.value === currentValue) {
                  changeValue(null, onChangeAdditional);
                }
              }}
            >
              <Flex justify='space-between' align='center' gap={8}>
                <span className='inline-block capitalize-first-letter'>{value}</span>
                {additionalContent && additionalContent}
              </Flex>
            </Radio>
          ))}
        </Flex>
      ))}
    </Radio.Group>
  );

  if (choices.length >= CHOICES_LIMIT_LIST) {
    if (window.innerWidth < breakpointMobile) {
      const showDrawer = () => {
        setDrawerVisible(true);
      };
      return (
        <>
          <Input
            className='custom-input-suffix-btn-icon'
            id={key}
            placeholder='Sélectionnez'
            defaultValue={defaultValue}
            value={currentValue}
            onClick={showDrawer}
            suffix={<Button onClick={showDrawer} className='btn-icon' size='large' type='text' icon={<RightOutlined className='icon text-primary' />} />}
            readOnly
          />
          <Drawer open={drawerVisible} onClose={() => setDrawerVisible(false)} className='drawer-attributes-list'>
            {radioGroup(() => {
              setDrawerVisible(false);
            })}
          </Drawer>
        </>
      );
    }

    const options = groupedChoices
      .reduce((acc, choices) => acc.concat(choices), [])
      .map(({ id, value, additionalContent }) => ({
        key: `${id}-${value}`,
        value: (
          <Radio
            className='width-parent not-clickable'
            key={id}
            checked={value === currentValue}
            onClick={() => {
              if (value === currentValue) {
                changeValue(null);
              } else {
                changeValue(value);
              }
            }}
          >
            <Flex justify='space-between' align='center' gap={8}>
              <span className='inline-block capitalize-first-letter'>{value}</span>
              {additionalContent && additionalContent}
            </Flex>
          </Radio>
        ),
      }));

    return (
      <CustomSelect
        className='custom-select-attribute'
        placeholder='Sélectionnez'
        value={currentValue}
        options={options}
        virtual={false}
        showSearch
        filterOption={(input, option) => {
          const keyValue = option.key || '';
          const value = keyValue.split('-')[1] || '';
          return value.toLowerCase().includes(input.toLowerCase());
        }}
        openOverride={dropdownVisible}
        onDropdownVisibleChange={(visible) => {
          if (visible) {
            setDropdownVisible(true);
          }
        }}
        onBlur={() => setDropdownVisible(false)}
        setOpenOverride={setDropdownVisible}
        onSelect={(val) => {
          val.props.onClick();
          setDropdownVisible(false);
        }}
      />
    );
  }
  return radioGroup();
}

function CheckboxInput({ propagateChange, attribute, defaultValue }) {
  const { key, groupedChoices, choices } = attribute;
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const changeValue = (val, onChangeAdditional = () => {}) => {
    onChangeAdditional();
    propagateChange(key, val);
    setCurrentValue(val);
  };

  const checboxGroup = (onChangeAdditional = () => {}) => (
    <Checkbox.Group
      className='custom-check-group'
      defaultValue={defaultValue}
      value={currentValue}
      onChange={(value) => changeValue(value, onChangeAdditional)}
    >
      {groupedChoices.map((choices, index) => (
        <Flex key={`group-${index}`} className='grouped-choices-wrapper' wrap='wrap' gap={16}>
          {choices.map(({ id, value, additionalContent }) => (
            <Checkbox
              key={id}
              value={value}
              onClick={(e) => {
                if (e.target.value === currentValue) {
                  changeValue(null, onChangeAdditional);
                }
              }}
            >
              <Flex justify='space-between' align='center'>
                <span className='inline-block capitalize-first-letter'>{value}</span>
                {additionalContent && additionalContent}
              </Flex>
            </Checkbox>
          ))}
        </Flex>
      ))}
    </Checkbox.Group>
  );

  if (choices.length >= CHOICES_LIMIT_LIST) {
    const currentValueStr = currentValue ? currentValue.join(', ') : '';

    if (window.innerWidth < breakpointMobile) {
      const showDrawer = () => {
        setDrawerVisible(true);
      };

      return (
        <>
          <Input
            className='custom-input-suffix-btn-icon'
            placeholder='Sélectionnez'
            value={currentValueStr}
            onClick={showDrawer}
            suffix={<Button onClick={showDrawer} className='btn-icon' size='large' type='text' icon={<RightOutlined className='icon text-primary' />} />}
            readOnly
          />
          <Drawer open={drawerVisible} onClose={() => setDrawerVisible(false)} className='drawer-attributes-list'>
            {checboxGroup()}
          </Drawer>
        </>
      );
    }

    const options = groupedChoices
      .reduce((acc, choices) => acc.concat(choices), [])
      .map(({ id, value, additionalContent }) => ({
        key: id,
        value: (
          <Checkbox
            className='width-parent not-clickable'
            key={id}
            checked={currentValue && currentValue.includes(value)}
            onClick={() => {
              if (currentValue && currentValue.includes(value)) {
                changeValue(currentValue.filter((val) => val !== value));
              } else {
                changeValue(currentValue ? [...currentValue, value] : [value]);
              }
            }}
          >
            <Flex justify='space-between' align='center'>
              <span className='inline-block capitalize-first-letter'>{value}</span>
              {additionalContent && additionalContent}
            </Flex>
          </Checkbox>
        ),
      }));

    return (
      <CustomSelect
        className='custom-select-attribute'
        placeholder='Sélectionnez'
        value={currentValueStr}
        options={options}
        virtual={false}
        openOverride={dropdownVisible}
        onDropdownVisibleChange={(visible) => {
          if (visible) {
            setDropdownVisible(true);
          }
        }}
        onBlur={() => setDropdownVisible(false)}
        setOpenOverride={setDropdownVisible}
        onSelect={(val) => val.props.onClick()}
      />
    );
  }
  return checboxGroup();
}

function InputTypeMapper({ dispatch, attribute, defaultValue, overridePropagateChange, askConfirmation }) {
  const { type } = attribute;

  const propagateChange = (key, value) => {
    if (overridePropagateChange) {
      overridePropagateChange(key, value);
      return;
    }
    dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY,
        substate_key: key,
        value: value,
      },
    });
  };

  switch (type) {
    case AD_DETAILS_API_IDS.TYPES.STRING:
      return <AutocompleteInput propagateChange={propagateChange} attribute={attribute} defaultValue={defaultValue} />;
    case AD_DETAILS_API_IDS.TYPES.DECIMAL:
    case AD_DETAILS_API_IDS.TYPES.INT:
      return <InputOnlyNumber propagateChange={propagateChange} attribute={attribute} defaultValue={defaultValue} askConfirmation={askConfirmation} />;
    case AD_DETAILS_API_IDS.TYPES.BOOL:
      return <SwitchInput propagateChange={propagateChange} attribute={attribute} defaultValue={defaultValue} />;
    case AD_DETAILS_API_IDS.TYPES.RADIO.MAIN_KEY:
      if (attribute[AD_DETAILS_API_IDS.TYPES.RADIO.MULTIPLE_STATUS_KEY]) {
        return <CheckboxInput propagateChange={propagateChange} attribute={attribute} defaultValue={defaultValue} />;
      }
      return <RadioInput propagateChange={propagateChange} attribute={attribute} defaultValue={defaultValue} />;
  }
  return <>UNKNOWN</>;
}

function Attribute({ dispatch = null, attribute, defaultValue, overridePropagateChange = null, askConfirmation = false }) {
  const { label, required, helptext } = attribute;

  return (
    <Flex className='attribute-item' vertical gap={8}>
      <Flex gap={8} vertical className='attribute-label-block'>
        <p className='body2'>
          {label}
          {required && <Asterisk />}
        </p>
        {helptext && <p className='subtext'>{helptext}</p>}
      </Flex>
      <div className='attribute-input-block'>
        <InputTypeMapper
          dispatch={dispatch}
          attribute={attribute}
          defaultValue={defaultValue}
          overridePropagateChange={overridePropagateChange}
          askConfirmation={askConfirmation}
        />
      </div>
    </Flex>
  );
}

export default Attribute;
