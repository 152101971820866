import { useMutation, useQuery } from '@tanstack/react-query';
import { paramsToURL } from '~/components/screens/Conversations/Conversations';
import { api } from '~/services/api';

import { treatApiError } from './utils';

const TIMEOUT = 1000 * 60 * 5; // 5 minutes
const SHORT_TIMEOUT = 1000 * 60; // 1 minute
const STALE_TIME = 1000 * 60 * 15; // 15 minutes

export const useConversations = (merchantId, params) => {
  return useQuery({
    queryKey: ['conversations', merchantId, params],
    queryFn: () =>
      api.get(`/${merchantId}/conversation/${paramsToURL(params)}`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    refetchInterval: TIMEOUT,
    staleTime: STALE_TIME,
  });
};

export const useMessagerieSummary = (merchantId) => {
  return useQuery({
    queryKey: ['conversationsToReadSummary', merchantId],
    queryFn: () =>
      api.get(`/${merchantId}/conversation/to_read/summary/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    refetchInterval: TIMEOUT,
    staleTime: STALE_TIME,
  });
};

export const useNewMessagesNotification = (merchantId) => {
  return useQuery({
    queryKey: ['conversationsCountToRead', merchantId],
    queryFn: () =>
      api.get(`/${merchantId}/conversation/to_read_count/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    refetchInterval: SHORT_TIMEOUT,
  });
};

export const useConversation = (conversationId) => {
  return useQuery({
    queryKey: ['conversation', conversationId],
    queryFn: () =>
      api.get(`/conversation/${conversationId}/?mark_as_read=true`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    refetchInterval: TIMEOUT,
    staleTime: STALE_TIME,
  });
};

export const useSendMessage = (conversationId, queryClient) => {
  return useMutation({
    mutationFn: (data) =>
      api
        .post(`/conversation/${conversationId}/message/`, {
          body: data,
        })
        .then((res) => {
          if (res.success) {
            return res.data;
          }
          treatApiError(res);
        }),
    onSuccess: (data) => {
      queryClient.setQueryData(['conversation', data.id], data);
      queryClient.invalidateQueries(['conversations']);
    },
  });
};

export const useCloseConversation = (conversationId, queryClient) => {
  return useMutation({
    mutationFn: () =>
      api.patch(`/conversation/${conversationId}/close/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(['conversation', data.id], data);
      // Update the status of the conversation in the conversations list
      queryClient.invalidateQueries(['conversations']);
    },
  });
};

export const useCreateConversation = (orderId, queryClient, postSuccess) => {
  return useMutation({
    mutationFn: (data) => {
      data.append('merchant_order_id', orderId);
      return api
        .post(`/conversation/create/`, {
          body: data,
        })
        .then((res) => {
          if (res.success) {
            return res.data;
          }
          treatApiError(res);
        });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(['conversations']);
      postSuccess(data);
    },
  });
};

export const useGetAttachmentUrl = (conversationId, attachmentId) => {
  return useQuery({
    queryKey: ['attachment', conversationId, attachmentId],
    queryFn: () =>
      api.get(`/conversation/${conversationId}/attachment/${attachmentId}/`).then((res) => {
        if (res.success) {
          return res.data.url;
        }
        treatApiError(res);
      }),
    enabled: false,
    retry: false,
  });
};
