import { CloseOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Flex, Input } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { api } from '~/services/api';

function MessagerieSatisfaction({ activeMerchantId }) {
  const [disabled, setDisabled] = useState(false);
  const [quality, setQuality] = useState(null);
  const [comment, setComment] = useState('');
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['messagerieSatisfaction'],
    queryFn: () =>
      api.get(`/${activeMerchantId}/messagerie/satisfaction/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        return null;
      }),
  });

  const mutation = useMutation({
    mutationFn: () =>
      api.put(`/${activeMerchantId}/messagerie/satisfaction/`, {
        body: JSON.stringify({
          rating: quality,
          comment: comment,
        }),
      }),
    onSuccess: () => {
      queryClient.setQueryData(['messagerieSatisfaction'], true);
    },
  });

  if (data) return null;
  const qualityValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const submit = () => {
    if (!quality) return;
    mutation.mutate();
    setQuality(null);
    setComment('');
  };

  const close = () => {
    setDisabled(true);
  };
  if (disabled) return null;

  return (
    <Flex vertical gap={16} className='px-s py-xs rounded-[12px] bg-white border border-solid border-grey bottom-s right-s absolute group'>
      <Flex align='center' justify='space-between' gap={8}>
        <p className='body2'>Que pensez-vous de la nouvelle messagerie ?</p>
        <Button type='text' className='btn-icon' icon={<CloseOutlined />} onClick={close} />
      </Flex>
      <Flex vertical gap={16} className='group-hover:flex hidden'>
        <Flex vertical gap={8}>
          <Flex gap={8} align='center' justify='space-between'>
            {qualityValues.map((value) => (
              <Flex
                className={`w-[40px] h-[40px] p-[10px] text-base rounded-sm border border-solid border-grey-dark cursor-pointer hover:border-primary-dark hover:bg-primary-light ${value === quality && ' bg-primary-light border-primary-dark'}`}
                justify='center'
                align='center'
                key={value}
                onClick={() => setQuality(value)}
              >
                {value}
              </Flex>
            ))}
          </Flex>
          <Flex align='center' justify='space-between'>
            <p className='body1'>Très mauvaise</p>
            <p className='body1'>Excellente</p>
          </Flex>
        </Flex>

        <Input.TextArea placeholder='(Facultatif)' value={comment} onChange={(e) => setComment(e.target.value)} />

        <Button type='primary' className='btn-success' block loading={mutation.isPending} onClick={submit}>
          Valider
        </Button>
      </Flex>
    </Flex>
  );
}

export default connect(({ session }) => ({
  activeMerchantId: session.active_merchant.id,
}))(MessagerieSatisfaction);
