import { useQueries, useQuery } from '@tanstack/react-query';
import { api } from '~/services/api';

import { treatApiError } from './utils';

export const useOrderDetails = (orderId, merchantId) => {
  const queries = [
    {
      queryKey: ['orderDetails', orderId, merchantId],
      queryFn: () =>
        api.get(`/${merchantId}/izberg/order/${orderId}/?full=true`).then((res) => {
          if (res.success) {
            return res.data;
          }
          treatApiError(res);
        }),
    },
    {
      queryKey: ['orderStatusHistory', merchantId, orderId],
      queryFn: () =>
        api.get(`/${merchantId}/orders/${orderId}/transition_log/`).then((res) => {
          if (res.success) {
            return res.data.objects;
          }
          treatApiError(res);
        }),
    },
    {
      queryKey: ['orderShippingOptions', merchantId, orderId],
      queryFn: () =>
        api.get(`/${merchantId}/izberg/order/${orderId}/shipping_options/`).then((res) => {
          if (res.success) {
            return res.data.objects;
          }
          treatApiError(res);
        }),
    },
    {
      queryKey: ['orderCommissions', merchantId, orderId],
      queryFn: () =>
        api.get(`/${merchantId}/orders/${orderId}/commissions/`).then((res) => {
          if (res.success) {
            return res.data.objects;
          }
          treatApiError(res);
        }),
    },
  ];

  return useQueries({
    queries: queries,
    combine: (results) => {
      const [orderDetails, orderStatusHistory, orderShippingOptions, orderCommissions] = results;
      return {
        order: {
          ...orderDetails.data,
          items: orderDetails.data?.items.map((item) => ({
            ...item,
            shipping: orderShippingOptions?.data.find((shipping) => shipping.order_items.find((orderItem) => orderItem.id === item.id)),
          })),
          status_history: orderStatusHistory.data,
          commissions: orderCommissions.data,
          shipping_options: orderShippingOptions.data,
        },
        pending: results.some((result) => result.isPending),
        error: results.find((result) => result.isError)?.error,
      };
    },
  });
};

export const useOrderItem = (itemId, merchantId) => {
  return useQuery({
    queryKey: ['orderItem', itemId, merchantId],
    queryFn: () =>
      api.get(`/${merchantId}/izberg/order_item/${itemId}/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
  });
};

export const useOrderDetailByIdNumber = (idNumber, merchantId) => {
  return useQuery({
    queryKey: ['orderDetailByIdNumber', idNumber, merchantId],
    queryFn: () =>
      api.get(`/${merchantId}/merchant_order/get/?id_number=${idNumber}`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    enabled: idNumber.length > 14,
    retry: false,
  });
};
