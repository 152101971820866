import Icon from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Divider, Flex } from 'antd';
import React, { useState } from 'react';
import CustomCheckmarkIcon from '~/assets/svg/icon-checkmark.svg?react';
import { useCloseConversation, useSendMessage } from '~/hooks/useConversations';

import SendMessageForm from './SendMessageForm';

function SendMessage({ conversationId, isClosed }) {
  const [showMessageForm, setShowMessageForm] = useState(false);
  const queryClient = useQueryClient();

  const sendMessageMutation = useSendMessage(conversationId, queryClient);
  const closeConversationMutation = useCloseConversation(conversationId, queryClient);

  if (isClosed && !showMessageForm) {
    return (
      <Flex gap={16} justify='space-between' align='center' className='p-s bg-primary-light border-t border-b-0 border-x-0 border-solid border-grey'>
        <p className='body1'>
          Cette conversation est clôturée. En envoyant un nouveau message, vous autorisez le ou la client·e à vous contacter via cette messagerie.
        </p>
        <Button onClick={() => setShowMessageForm(true)} type='primary'>
          Envoyer un nouveau message
        </Button>
      </Flex>
    );
  }

  return (
    <Flex vertical gap={8} align='center'>
      <Divider />
      {!isClosed && (
        <Button
          onClick={() => closeConversationMutation.mutate()}
          loading={closeConversationMutation.isPending}
          disabled={closeConversationMutation.isPending}
          className='w-fit'
        >
          <Icon component={CustomCheckmarkIcon} />
          Clôturer la conversation
        </Button>
      )}
      <SendMessageForm mutation={sendMessageMutation} />
    </Flex>
  );
}

export default SendMessage;
