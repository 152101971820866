import { Flex, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import NoConversationImg from '~/assets/jumelles.png';
import Dialogue from '~/components/shared/Dialogue/Dialogue';
import { SEARCH_PARAMETERS_API_IDS } from '~/helpers/conversation';
import { useConversations } from '~/hooks/useConversations';

import CreateConversationModal from '../ConversationSearch/CreateConversationModal/CreateConversationModal';
import ConversationDetails from './ConversationDetails/ConversationDetails';
import TimeNotification from './TimeNotification';

const cleanLastMessage = (message) => {
  let cleanedMessage = message;
  if (cleanedMessage.indexOf('\n') > -1) {
    cleanedMessage = `${cleanedMessage.split('\n')[0]}...`;
  }
  if (cleanedMessage.includes('<b>')) {
    cleanedMessage = cleanedMessage.replace('<b>', '').replace('</b>', '');
  }
  return cleanedMessage;
};

function ConversationsList({ params, activeMerchantId, selectedConversation, setSelectedConversation }) {
  const { isLoading, data } = useConversations(activeMerchantId, params);

  if (isLoading) {
    return (
      <Flex justify='center' align='center' className='h-full'>
        <Spin size='large' />
      </Flex>
    );
  }
  const noDataWithThisParams = data.length === 0 && params[SEARCH_PARAMETERS_API_IDS.ORDER_ID];

  return (
    <>
      <Flex gap={32} className='max-h-[75vh]'>
        <Flex
          className={`w-[260px] overflow-auto border-r border-y-0 border-l-0 border-solid border-grey ${noDataWithThisParams ? 'hidden' : ''}`}
          vertical
          gap={16}
        >
          <Flex vertical>
            {data?.map((conversation) => (
              <Flex
                key={conversation.id}
                onClick={() => {
                  setSelectedConversation(conversation.id);
                  conversation.read_by_merchant = true;
                }}
                vertical
                gap={8}
                // We have to cancel other borders due to preflight not setup
                className={`p-m border-b border-x-0 border-t-0 border-solid border-grey ${selectedConversation === conversation.id && 'bg-primary-light'}`}
              >
                <Flex justify='space-between' align='center' gap={10}>
                  <p className={`${!conversation.read_by_merchant ? 'body2' : 'body1'} text-ellipsis line-clamp-1 break-all`}>
                    {!conversation.user.deleted ? `${conversation.user.first_name} ${conversation.user.last_name}` : 'Utilisateur supprimé'}
                  </p>
                  <TimeNotification conversation={conversation} />
                </Flex>
                <p className={`${!conversation.read_by_merchant ? 'caption2' : 'caption1'} text-grey-medium`}>N°{conversation.order_reference}</p>
                <p className={`${!conversation.read_by_merchant ? 'caption2' : 'caption1'} overflow-hidden text-ellipsis line-clamp-1 break-all`}>
                  {cleanLastMessage(conversation.last_message)}
                </p>
              </Flex>
            ))}
          </Flex>
        </Flex>

        {selectedConversation && <ConversationDetails conversationId={selectedConversation} />}

        {noDataWithThisParams && (
          <Flex className='w-full h-[75vh]' justify='center' align='center' vertical gap={24}>
            <img src={NoConversationImg} alt='' width={90} />
            <Dialogue type='info' light message='Il n’y a pas de conversation existante avec ce numéro de commande à l’horizon...' />
            <CreateConversationModal
              setSelectedConversation={setSelectedConversation}
              baseOrderIdNumber={params[SEARCH_PARAMETERS_API_IDS.ORDER_ID]}
              customText='Nouveau message à partir d’un n° de commande'
            />
          </Flex>
        )}
      </Flex>
    </>
  );
}

export default connect(({ session }) => ({ activeMerchantId: session.active_merchant.id }))(ConversationsList);
